import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import logo from '../images/logo-reduzida.png';

const AnyReactComponent = ({ text }) => (
  <div style={{width: '70px', textAlign: 'center'}}>
    <img alt="Mandetta" src={logo} style={{ width: '40px', margin: 'auto', display: 'block', padding: '5px'}}/>
      {text}
  </div>
)

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: -22.984104665857586,
      lng: -43.208181773021344,
    },
    zoom: 16,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '420px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBazg_SMzfjkEsLk0qTchvQlyrs70vDiVE' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={this.props.center.lat}
            lng={this.props.center.lng}
            text=""
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
