import React from 'react';
import styled from '@emotion/styled';
import logo from '../images/logo.svg';
import arrow from '../images/scroll-down-icon.png';
import CarouselBox from '../components/Carousel';

const ImgLogo = styled.img`
    width: 50%;
    margin: auto;
    display: block;
    height: calc(100% - 170px);
`;

const ArrowIcon = styled.img`
    display: block;
    height: 30px;
    margin: auto;
`;

export default () => (
    <div id="home">
        <div className="full-width d-md-none splash-mobile">
            <ImgLogo src={logo} />
            <p style={{width:'100%', textAlign:"center"}}>Bem Vindo</p>
            <div className="bounce">
                <ArrowIcon src={arrow} />
            </div>
        </div>
        <div>
            <h2 style={{ position:'absolute', color:"#fff", textAlign:'center', marginTop:'.9em', fontSize:'3.2em'}}>
                Bem Vindo
            </h2>
            <CarouselBox />
        </div>
    </div>
);
