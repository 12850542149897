import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from '../components/Image800';

const SociasAlt = "Marina Mandetta e Juliana Gregório";

const QuemSomos = () => (
    <div className="container-fluid" id="quemsomos">
        <div className="bg-mandetta" />
        <Row className="no-gutters">
            <Col xs={12}>
                <h2 className="titulo-principal">Quem Somos</h2>
                <article id="socias">
                    <Row>
                        <Col md={6}>
                            <div className="img-socias">
                                <Image className="full-width d-md-none" alt={SociasAlt} imgName="socias.jpg" />
                                <Image className="d-none d-md-block d-lg-none" alt={SociasAlt} imgName="tablet-socias.jpg" />
                                <Image className="d-none d-lg-block" alt={SociasAlt} imgName="desktop-socias.jpg" />
                            </div>
                        </Col>
                        <Col md={6} className="texto-socias">
                            <p>Com sede no Rio de Janeiro, o escritório Mandetta Advogados exerce com <strong className="laranja">excelência</strong> a representação de seus clientes, atuando em todas as instâncias, através da prestação de serviços completos e especializados de advocacia, seguindo os princípios constitucionais da <strong className="laranja">ética, moral e respeito</strong> às organizações <span>do Estado.</span></p>
                        </Col>
                    </Row>
                </article>
                <article id="vocacaoeatuacao">
                    <h4>Vocação</h4>
                    <p>A vocação do escritório está no desenvolvimento de soluções <strong className="laranja">eficazes</strong> às pessoas físicas e jurídicas em diversas áreas do Direito, com destaque para as áreas de Contencioso Civil, Contratos e Intermediação de Negócios, Família, Tributário, Propriedade Intelectual e Entretenimento.</p>
                    <h4 className="mt-5">Atuação</h4>
                    <p>A atuação do Mandetta Advogados é pautada pelo <strong className="laranja">comprometimento</strong> com uma entrega assertiva e a busca constante por decisões que favoreçam os nossos clientes e os afastem de riscos. A nossa equipe é formada por profissionais <strong className="laranja">criativos</strong> que estão em constante atualização e aptos a prestar um atendimento personalizado, minucioso e de <strong className="laranja">qualidade</strong>.</p>
                    <p className="text-center text-md-left mt-3"><strong>Seja bem-vindo.</strong></p>
                </article>
            </Col>
        </Row>
    </div>
);

export default QuemSomos;
