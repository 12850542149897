import React from 'react';
import Helmet from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import BemVindo from '../sections/bemvindo';
import QuemSomos from '../sections/quemsomos';
import NossoTime from '../sections/nossotime';
import OndeAtuamos from '../sections/ondeatuamos';
import Contato from '../sections/contato';


const Home = () => {
  return (
    <Layout bodyClass="page-home">
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Mandetta Advocacia"
        />
      </Helmet>
      <div className="intro">
        <BemVindo />
        <Fade bottom> 
          <QuemSomos />
          <NossoTime />
          <OndeAtuamos />
          <Contato />
        </Fade>
      </div>
    </Layout>
  );
};

export default Home;
