import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { css } from '@emotion/core';
import Image from '../components/Image300';

const LinkedinIcon = () => <FontAwesomeIcon icon={faLinkedin} />;
const EmailIcon = () => <FontAwesomeIcon icon={faEnvelope} />;


const Colaboradores = [
  {
    key: 'adriana',
    title: 'Adriana Milhoranse',
    bio:
      'Formada pela Universidade Moacyr Sreder Bastos no ano de 2012, é pós-graduada na Escola Magistratura do Estado do Rio de Janeiro (EMERJ) em Direito Tributário. A advogada atua na área de Direito Securitário e Tributário.',
    email: 'adriana.milhoranse@mandetta.adv.br',
    picture: 'equipe-foto-adriana.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'juliana',
    title: 'Juliana Gregório',
    bio:
      'Formada pela Universidade da Cidade no ano de 2010, Pós-graduada em Gestão Empresarial e Processo Civil pela IBMEC, tendo ainda realizado Curso de Extensão em Direito Imobiliário na Escola de Magistratura do Estado do Rio de Janeiro (EMERJ). Juliana é sócia do escritório Mandetta Advogados e atua nas ações estratégicas do Contencioso Cível.',
    email: 'juliana.gregorio@mandetta.adv.br',
    picture: 'equipe-foto-juliana.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'nathalia',
    title: 'Nathalia Schiatti',
    bio:
      'Formada pela Universidade Católica de Petrópolis no ano de 2010, possui MBA em Gestão Jurídica do Seguro e Resseguro pela FUNENSEG – 2018, tendo realizado Curso de Extensão em Direito, Seguro, Resseguro e Regulação – Fundação Getúlio Vargas – 2017, Legal English: International Contracts & Business Law – Fundação Getúlio Vargas – 2018 e em Processos Técnicos do Resseguro – The Chartered Insurance Institute – London (2019). A advogada atua especialmente nas ações de direito securitário. ',
    email: 'nathalia.schiatti@mandetta.adv.br',
    picture: 'equipe-foto-natalia.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'marina',
    title: 'Marina Mandetta',
    bio:
      'Formada pela Universidade Federal do Rio de Janeiro no ano de 2015, Pós-graduada em Propriedade Intelectual pela PUC-Rio (2017), tendo realizado Curso de Extensão em Música e Negócios – PUC-Rio (2015).  Marina é sócia do escritório Mandetta Advogados e atua nas áreas de Propriedade Intelectual e Entretenimento, Contratos e Intermediação de Negócios.',
    email: 'marina.mandetta@mandetta.adv.br',
    picture: 'equipe-foto-marina.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'andrea-duarte',
    title: 'Andrea Duarte',
    bio: 'Técnico em Contabilidade no SENAC em 1998.',
    email: 'andrea.duarte@mandetta.adv.br',
    picture: 'andrea-valenca.jpeg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'marina-valenca',
    title: 'Marina Valença',
    bio: 'Formada pela Universidade Estácio de Sá - UNESA  no ano de 2019, Pós-graduanda em Direito Digital e Compliance, pela UniAmérica, Centro Universitário. A advogada atua especialmente na área de Contencioso Cível.',
    email: 'marina.valenca@mandetta.adv.br',
    picture: 'marina-valenca.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
  {
    key: 'luiza',
    title: 'Luiza Carneiro',
    bio:
      'Formada pela Universidade Federal do Estado do Rio de Janeiro no ano de 2015, Pós-graduada em Gênero e Direito pela Escola de Magistratura do Estado do Rio de Janeiro (EMERJ) – 2017. A advogada atua especialmente na área de Contencioso Cível. ',
    email: 'luiza.carneiro@mandetta.adv.br',
    picture: 'equipe-foto-luiza.jpg',
    linkedinicon: '',
    linkedinuser: '',
  },
];

export default () => {
  return (
    <div className="container-fluid" id="nossotime">
      <div className="row no-gutters">
        <div className="col-12">
          <h2>Nosso Time</h2>
          <article>
            <p>Nosso time está apto a representar nossos clientes em todas as instâncias, inclusive nos tribunais superiores, desenvolvendo estratégias consistentes, obtendo importantes decisões e grandes êxitos.</p>
          </article>
          <Tab.Container defaultActiveKey="adriana" id="equipe" mountOnEnter>
            <Row>
              <Col sm={12} md={6}>
                <Nav variant="pills" className="full-width mt-0">
                  {Colaboradores
                    .sort((a, b) => (a.key > b.key ? 1 : -1))
                    .map(pessoa => (
                    <Nav.Item key={pessoa.key}>
                      <Nav.Link eventKey={pessoa.key} title={pessoa.title}>
                        <Image imgName={pessoa.picture} className="img-container d-none d-md-inline-block"/>
                        <span>
                          {pessoa.title}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12} md={6} className="nossotime__bio">
                <Tab.Content>
                  {Colaboradores.map(pessoa => (
                    <Tab.Pane eventKey={pessoa.key} key={pessoa.key}>
                      {
                        pessoa.picture ? <Image imgName={pessoa.picture} className="img-container"/> : null
                      }
                      
                      <h3>{pessoa.title}</h3>
                      <p>{pessoa.bio}</p>
                      <div>

                        {
                          pessoa.linkedinuser
                            ? <a href={`${pessoa.linkedinuser}`}>{pessoa.linkedinicon}</a>
                            : null
                        }

                        {
                          pessoa.email
                            ? <a href={`mailto:${pessoa.email}`} title={pessoa.email}><EmailIcon /></a>
                            : null
                        }

                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
              <Col sm={12}>
                <Nav variant="pills" className="full-width d-md-none">
                  {Colaboradores.map(pessoa => (
                    <Nav.Item key={pessoa.key}>
                      <Nav.Link eventKey={pessoa.key} title={pessoa.title}>{pessoa.title}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};
