import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Carousel from 'react-bootstrap/Carousel';

export default () => (
 <StaticQuery
    query={graphql`
    query CarouselPics {
      allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
        edges {
          node {
            childImageSharp {
              id
              fluid(maxWidth: 1370, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `}
  render={data => (
      <div className="d-none d-md-block">
        <Carousel interval="3500" fade={true}>
          {data.allFile.edges.map(pic => 
            <Carousel.Item key={pic.node.childImageSharp.id}>
              <Img fluid={pic.node.childImageSharp.fluid}/>
            </Carousel.Item>
          )}
        </Carousel>
      </div>
  )}
    />
);
