import React, { useState } from 'react';
import icon_prev from '../images/area_icon_preventiva.png';
import icon_contencioso from '../images/area_icon_contencioso.png';
import icon_contrato from '../images/area_icon_contratos.png';
import icon_consumo from '../images/area_icon_consumo.png';
import icon_entretenimento from '../images/area_icon_entretenimento.png';
import icon_familia from '../images/area_icon_familia.png';
import icon_intelectual from '../images/area_icon_intelectual.png';
import icon_securitario from '../images/area_icon_securitario.png';
import icon_trabalhista from '../images/area_icon_trabalhista.png';
import icon_tributario from '../images/area_icon_tributario.png';


const data = [
  {
    title: 'Advocacia Preventiva',
    paragraph: 'Entendemos que a prevenção de litígios e a minimização dos riscos é uma estratégia empresarial inteligente e econômica. <br>Através de consultorias especializadas buscamos orientar nossos clientes sobre como lidar diante de situações cotidianas, afastando a possibilidade de existência de demandas judiciais e extrajudiciais.',
    icon: icon_prev,
    key: "advocacia",
  },
  {
    title: 'Contencioso Civel e Empresarial',
    paragraph: 'Patrocinamos e administramos ações cíveis e de relações de consumo nos estados do Rio de Janeiro e São Paulo. Além disso, possuímos escritórios parceiros em grande parte do território nacional. <br>O grande diferencial da nossa gestão de contencioso reside no tratamento estratégico conferido às carteiras frequentemente tratadas como “massivas”, trazendo resultados satisfatórios com foco da <strong>redução de custos</strong> e <strong>minimização de prejuízos</strong> por parte dos nossos clientes.',
    icon: icon_contencioso,
    key: "contecioso",
  },
  {
    title: 'Contratos e Intermediação de Negócios',
    paragraph: 'Assessoramos nossos clientes na negociação, elaboração, revisão e análise de contratos preliminares e demais contratos previstos na legislação brasileira. ',
    icon: icon_contrato,
    key: "contratos",
  },
  {
    title: 'Securitário',
    paragraph: 'Acompanhando o esforço das seguradoras para <strong>combater fraudes</strong> e <strong>reduzir o volume de ações judiciais</strong>, prestamos serviços especializados na área de seguros, gerenciando contencioso de volume envolvendo diversas áreas tais quais Seguros de Saúde, Vida, Previdenciário e ramos elementares. <br>Acreditamos e incentivamos a realização de acordos como medida apta a reduzir o tempo e custo das demandas. O <strong>Mandetta Advogados</strong> é membro da AIDA (Associação Internacional de Direito de Seguros) e seus profissionais acompanham perenemente as mudanças na legislação e jurisprudência pátria, garantindo assertividade e resultados benéficos aos nossos clientes.',
    icon: icon_securitario,
    key: "securitario",
  },
  {
    title: 'Relações de Consumo',
    paragraph: 'Diariamente somos submetidos a inúmeras relações de consumo e, por outro lado, toda e qualquer pessoa que pense em abrir um negócio, deve se preocupar primordialmente com as leis que regem as relações entre o fornecedor e consumidor. Pensando nisso, o escritório <strong>Mandetta Advogados</strong> possui uma excelente equipe com vasta experiência na área consumerista, apta a desenvolver trabalho assertivo e de qualidade, tanto na defesa dos direitos do consumidor como também do fornecedor de serviços.',
    icon: icon_consumo,
    key: "consumo",
  },
  {
    title: 'Tributário',
    paragraph: 'Em parceria com escritórios especializados, prestamos os seguintes serviços: <br>-Consultoria, planejamento fiscal e tributário de impostos federais, estaduais e municipais<br>-Contencioso Administrativo e Judicial',
    icon: icon_tributario,
    key: "tributario",
  },
  {
    title: 'Família',
    paragraph: 'A evolução do conceito de família trouxe a necessidade de adequação do mundo jurídico aos mais diversos formatos e composições familiares. Ainda assim, a proteção do núcleo familiar é essencial, razão pela qual os métodos auto compositivos são amplamente incentivados pelo <strong>Mandetta Advogados</strong>. <br>Dentre os serviços prestados na área de família destacam-se: Divórcio, Reconhecimento de União Estável, Dissolução de União Estável, Partilha Judicial, Pacto Antenupcial, Ação de Alimentos, Execução de Alimentos, Guarda e Regulamentação de visitas, Investigação de Paternidade, Interdição Judicial',
    icon: icon_familia,
    key: "familia",
  },
  {
    title: 'Trabalhista Empresarial',
    paragraph: 'Atualmente é imprescindível que as empresas implantem, monitorem e garantam a efetividade de padrões mínimos de governança e compliance, especialmente nas relações trabalhistas firmadas, não somente nas relações de emprego, mas também nas relações com os sócios, acionistas, prestadores de serviços, autônomos, terceiros, ou seja, todas as relações que envolvam trabalho. <br>Dentre os serviços que englobam nossa atuação na área trabalhista destacam-se: (i) Avaliação dos riscos trabalhistas inerentes às operações pretendidas pela empresa, inclusive por meio de due diligences legais e reestruturação de departamentos de pessoal e de recursos humanos de empresas; (ii) Mapeamento do passivo trabalhista (existente e oculto); (iii) Análise de estratégia para redução do passivo trabalhista para demandas já existentes; (iv) Defesa dos interesses da empresa no contencioso trabalhista judicial e administrativo"', 
    icon: icon_trabalhista,
    key: "trabalhista",
  },
  {
    title: 'Entretenimento',
    paragraph: 'Possuímos expertise nos variados ramos da economia criativa, atuando especialmente no planejamento, consultoria e <strong>viabilização de negócios</strong> do setor musical, audiovisual, teatral, literário e terceiro setor. Estamos aptos a prestar assessoria jurídica para produtoras, artistas, editoras, gravadoras, selos, distribuidores e demais titulares de direitos. <br>Dentre os serviços oferecidos pelo escritório, destacam-se a análise, elaboração e intermediação de contratos, clearence de direitos, gestão coletiva de direitos autorais e conexos, enquadramento nas leis de incentivo federais, estaduais e municipais e contencioso judicial.',
    icon: icon_entretenimento,
        key: "entretenimento",
  },
  {
    title: 'Propriedade Industrial',
    paragraph: 'Prestamos serviços de apresentação e acompanhamento de registro de marca e softwares perante o INPI, assessoria na elaboração de contratos de licenciamento e cessão de marca e mecanismos de proteção da concorrência desleal. <br>Adicionalmente, possuímos escritórios parceiros especializados na proteção de patentes, modelos de utilidade e transferência de tecnologia, aptos a prestar todos os serviços necessários à proteção dos nossos clientes.',
    icon: icon_intelectual,
    key: "industrial",
  },
];

class Accordion extends React.Component {
  render () {
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list full-width' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const AccordionItem = ( props ) => {
  const [state, setState] = useState({ opened: false })
  const { opened } = state
  const {
    paragraph,
    title,
    icon
  } = props

  return (
    <div
      {...{
        className: `accordion-item, ${opened && 'accordion-item--opened'}`,
        onClick: () => { setState({ opened: !opened }) }
      }}
    >
      <div {...{ className: 'accordion-item__line' }}>
        <img src={icon} {...{ className: 'accordion-item__icon-service'}}/>
        <h3 {...{ className: 'accordion-item__title' }}>
          {title}
        </h3>
        <span {...{ className: 'accordion-item__icon' }}/>
      </div>
        <div {...{ className: 'accordion-item__inner' }}>
          <div {...{ className: 'accordion-item__content' }}>
            <div {...{ className: 'accordion-item__paragraph' }}>
              <p dangerouslySetInnerHTML={{__html:paragraph}} />
              <div {...{className:'accordion-item__separador'}}/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Accordion;