import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

/* Optimized by Sarah on October with this solution https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05?m=MTU1MjY0MTA1NTE3Ng== */

const Image = ({ className, imgName, style }) => (
    <StaticQuery
        query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `}
        render={(data) => {
          const image = data.allImageSharp.edges.find(edge => edge.node.fluid.originalName === imgName);

          if (!image) {
            return null
          }
            return <Img fluid={image.node.fluid} style={style} className={className} />
        }}
    />
)
export default Image