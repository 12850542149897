import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {

  const data = useStaticQuery(graphql`
    query OndeAtuamosQuery {
        allOndeatuamosJson {
          edges {
            node {
              title
              paragraph
              icon {
                publicURL
              }
              id
            }
          }
        }
      }         
    `);

  return (
        <div className="tooltip-wrapper">
            {data.allOndeatuamosJson.edges.map(servico => (
            <div key={servico.node.id} >
                <div data-tip data-for={servico.node.id} className="tooltip-title">
                    <img src={servico.node.icon.publicURL} alt={servico.title} />
                    <h3>{servico.node.title}</h3>
                </div>
                <ReactTooltip type="light" effect="float" data-border="true" id={servico.node.id}>
                     <div>
                        <h3>{servico.node.title}</h3>
                        <p dangerouslySetInnerHTML={{__html:servico.node.paragraph }} />
                     </div>
                </ReactTooltip>
            </div>
            ))}
        </div>
  );
};
