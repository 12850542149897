import React from 'react';
import Accordion from '../components/accordion';
import Tooltip from '../components/Tooltip';
import Modal from '../components/Modal';

export default () => (
    <div className="container-fluid" id="areasdeatuacao">
        <div className="row no-gutters">
            <div className="col-12">
                <h2>Onde Atuamos</h2>
              <Accordion />
              <Tooltip />
            </div>
        </div>
    </div>
);
