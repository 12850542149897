import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mapa from '../components/Map2';

export default () => {
  const contato = [
    {
      address: ' Rua Visconde de Pirajá, 433, sala 802,  Ipanema',
      cidade: 'Rio de Janeiro/RJ - ',
      CEP: '22410-003',
      email: 'contato@mandetta.adv.br',
      tel: '21 3597 2229',
      telHref: '+552135972229',
      key: '1',
    },
  ];
  return (
        <div className="container-fluid" id="contato">
            <div className="row no-gutters">
                <div className="col-12">
                    <h2>Contato</h2>
                    <div className="full-width full-width-md">
                        <Mapa />
                    </div>
                    <Row>
                        <Col md={6}>
                            <h3>Endereço</h3>
                            <div style={{ position: 'relative' }}>
                                <div className="text-center icon-wrapper">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </div>
                                {contato.map(dados => (
                                    <div key={dados.key} className="contato-address">
                                        <p>
                                            {dados.address}
                                        </p>
                                        <p>
                                            {dados.cidade} CEP: {dados.CEP}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <h3>Contato</h3>
                            {contato.map(dados => (
                                <div key={dados.key}>
                                    <p>
                                    <FontAwesomeIcon icon={faEnvelope} size="xs"/>
                                    <a href={`mailto:${dados.email}`}>
                                    {dados.email}
                                    </a>
                                    </p>
                                    <p>
                                    <FontAwesomeIcon icon={faPhoneAlt} size="xs"/>
                                    <a href={`tel:${dados.telHref}`}>
                                    {dados.tel}
                                    </a>
                                    </p>
                                </div>
                            ))}
                        </Col>
                        <Col md={6}>
                            <h3>Mensagem</h3>
                            <Form className="full-width" name="contact" method="POST" data-netlify-recaptcha="true" data-netlify="true">
                                  <input type="hidden" name="form-name" value="contact" />
                                <Form.Group controlId="formName">
                                    <Form.Control type="text" placeholder="Nome:" aria-label="name" name="name"/>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="E-mail:" aria-label="email" name="email" />
                                </Form.Group>
                                <Form.Group controlId="formTel">
                                    <Form.Control type="tel" placeholder="Telefone:" aria-label="tel" name="tel"/>
                                </Form.Group>
                                <Form.Group controlId="formMsg">
                                    <Form.Control as="textarea" rows="5" placeholder="Mensagem:" aria-label="message" name="message"/>
                                </Form.Group>
                                <br/>
                                <div class="g-recaptcha" data-sitekey="6LdRWeQUAAAAAGaMv5Qa_202vb54e69jGujsMVH-"></div>
                                <Button variant="primary" type="submit">
                                    Enviar
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
  );
};
